'use strict';

Gri.module({
  name: 'sidebar-alpha',
  ieVersion: null,
  $el: $('.sidebar-alpha'),
  container: '.sidebar-alpha',
  fn: function () {
    var winwidth = $(document).width();
    var scrolltopheight = $('.header-alpha').height() + $('.breadcrumb-alpha').height() + 60;
    var stopPosition = $(document).height() - $('.footer-alpha').height() - 500;
    if(winwidth > 990){
          if($(document).scrollTop() > scrolltopheight){
            if(stopPosition > $(window).scrollTop()){
              $('.sidebar-alpha').css({
                  top: "20px",
                  position: "fixed"
                })
              console.log(stopPosition);
              console.log($(window).scrollTop());
            }else{
              $('.sidebar-alpha').css({
                top: "20px",
                position: "relative" 
              });
            }            
          }else{
            $('.sidebar-alpha').css({ 
                top: "20px",
                position: "relative" 
              });
          }
        }

      $(document).scroll(function() {
        var stopPosition = $(document).height() - $('.footer-alpha').height() - 500;
        if(winwidth > 990){
          if($(document).scrollTop() > scrolltopheight){
            if(stopPosition > $(window).scrollTop()){
              $('.sidebar-alpha').css({
                  top: "20px",
                  position: "fixed"
                })
              console.log(stopPosition);
              console.log($(window).scrollTop());
            }else{
              $('.sidebar-alpha').css({
                top: "20px",
                position: "relative" 
              });
            }            
          }else{
            $('.sidebar-alpha').css({ 
                top: "20px",
                position: "relative" 
              });
          }
        }
      });
  }
});
